.header-notifications-popup {
  .MuiPaper-root {
    width            : 350px;
    background-color:var(--color-white);
    border-radius    : var(--border-radius);
    color            : black;
    font-size        : 14px;
    margin-top       : 1rem;

    .message {
      padding : 12px 8px 4px;
      cursor  : pointer;
      display : flex;

      &:not(:last-child) {
        border-bottom: 1px solid lightgrey;
      }

      .img {
        width           : 15%;
        color           : var(--color-primary);
        display         : flex;
        justify-content : center;
        align-items     : center;
        padding-bottom  : 4px;

        svg {
          height: 35px;
          width: auto;
        }
      }

      .right-block {
        width: 90%;

        .text {
          color              : #515151;
          overflow           : hidden;
          text-overflow      : ellipsis;
          display            : -webkit-box;
          -webkit-line-clamp : 2;
          line-clamp         : 2;
          -webkit-box-orient : vertical;
        }

        .date {
          color: darkgray;
          text-align: end;
          font-size: 12px;
        }
      }


      &:hover {
        background-color: var(--color-primary-light-4);
      }
    }
  }
}

.toolbar {
  &.MuiToolbar-root {
    height: 69px;
    min-height: 69px;
    padding: 0 0 0 24px;
    display: flex;
    flex: 1;
  }
}

.dashboard-header {
  &.MuiPaper-root {
    background-color: #FFFFFF;
    box-shadow: none;
    border-bottom: 1px solid var(--color-gray-light-4);
    top: 0;
    position: sticky;
    width: 100%;
  }

  &__link-icon {
    width: 24px;
    height: 24px;

    &.disabled {
      cursor: not-allowed;

      &:hover {
        & > svg {
          & > path {
            fill: #5b6987;
          }
          & > g > path {
            fill: #5b6987;
          }
        }
      }
    }

    &:nth-child(2) {
      margin: 0 24px;
    }

    &:nth-child(3) {
      margin: 0 24px 0 0;
    }

    & > svg {
      width: 24px;
      height: 24px;
      & > path {
        fill: #5b6987;
      }
      & > g > path {
        fill: #5b6987;
      }
    }

    &:hover {
      & > svg {
        & > path {
          fill: var(--color-primary);
        }
        & > g > path {
          fill: var(--color-primary);
        }
      }
    }
  }
}

.profile-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notifications-popover {
  & .MuiPaper-root {
    margin-top: 15px;
    background-color: #FFFFFF;
    overflow: visible;
    border: var(--border);
    width: 400px;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      right: calc(50% - 5px);
      display: block;
      background-color: #FFFFFF;
      border-top: 1px solid var(--color-gray-light-4);
      border-left: 1px solid var(--color-gray-light-4);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      z-index: 10;
    }
  }
}

.notifications {
  &.MuiList-root {
    max-height: 400px;
    overflow: hidden;
    position: relative;
    z-index: 10;

    &:hover {
      overflow-y: auto;
    }
  }

  &__item {
    &.MuiListItem-root {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border-bottom: 1px solid var(--color-gray-light-4);
      gap: 10px;
      width: 400px;

      &:last-of-type {
        border: none
      }
    }

    &.disabled {
      cursor: auto;
    }

    &:not(.disabled):hover {
      background: var(--color-primary-light-4);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__message {
    &.MuiTypography-root {
      color: var(--color-secondary-dark-4);
      font-size: 1rem;
      margin: 0;

      &.unread {
        font-weight: 600;
      }
    }
  }

  &__date {
    &.MuiTypography-root {
      color: var(--color-gray-light);
      font-size: .8rem;
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > button {
      padding: 4px;
    }

    & > button > svg {
      width: 18px;
      height: 18px;
    }
  }

  &__delete {
    &:hover {
      svg > path {
        fill: var(--color-dangery);
      }
    }
  }

  &__apply {
    &:hover {
      svg > path {
        fill: var(--color-primary);
      }
    }
  }

  &__read {
    &:hover {
      svg > path {
        fill: var(--color-primary);
      }
    }
  }

  &__spinner {
    display         : flex;
    align-items     : center;
    justify-content : center;
    margin-top      : 5px;
  }
}
