.user-avatar {
  user-select         : none;
  max-width           : 2.5rem;
  max-height          : 2.5rem;
  min-width           : 1.375rem;
  min-height          : 1.375rem;
  aspect-ratio        : 1;
  border-radius       : 50%;
  display             : inline-block;
  vertical-align      : middle;
  background-size     : cover;
  background-position : center;
  background-repeat   : no-repeat;
}
