.basic-layout {
  height: calc(100% - 69px);

  header {
    height           : 69px;
    max-height       : 69px;
    width            : 100%;
    position         : fixed;
    background-color:var(--color-white);
    border-bottom    : 1px solid rgb(241, 241, 241);
    box-shadow       : rgb(0 0 0 / 3%) 1px 2px;
    display          : flex;
    justify-content  : space-between;
    align-items      : center;
    padding          : 0 1.5rem;
    z-index          : 99;

    .left-side-block, .right-side-block {
      height      : 100%;
      display     : flex;
      align-items : center;
    }

    .left-side-block {
      a {
        height          : 100%;
        display         : flex;
        align-items     : center;
        font-size       : 1.125rem;
        font-weight     : 600;
        text-decoration : none;
        color           : var(--color-secondary-dark-4);

        .logo-wrapper {
          height       : 100%;
          display      : flex;
          align-items  : center;
          margin-right : 1rem;

          .logo {
            min-width : 134px;
            max-width : 134px;
            height    : 40px;
          }
        }

        &.hide-name {
          .logo-wrapper {
            width    : 45px;
            overflow : hidden;
          }
        }
      }

    }

    .right-side-block {
      font-size: 0.875rem;

      > a {
        font-size      : 1rem;
        color          : var(--color-primary);
        margin-left    : 1rem;
        letter-spacing : 0.03rem;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
