@import "../assets/fonts/family/fonts.scss";

@import "./root.css";
@import "./reset.css";
@import "./mui.scss";
// deprecated
@import "helpers.scss";
@import "./shared/main.scss";
@import "./helpers/main.css";

// FONTS
html {
  font-family: 'Proxima Nova', sans-serif;
  height     : 100%;
}

// Tag styles
h1, h2, h3 {
  color: var(--color-secondary-dark-4);
  margin: 0;
}

h1 {
  font-weight: 300;
  font-size: 2.4rem;
}

h2 {
  font-weight: 400;
  font-size: 1.7rem;
}

h3 {
  font-weight: 600;
  font-size: 1.5rem;
}

h4 {
  font-weight: 600;
  line-height: 1.5;
}

// Additional classes
.cursor-pointer {
  cursor: pointer;
}

.mt-1rem {
  margin-top: 1rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.text-red {
  color: red;
}
.text-green {
  color: var(--color-success);
}
