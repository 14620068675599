.modal-component-wrapper {
  width            : 100vw;
  height           : 100vh;
  display          : flex;
  align-items      : center;
  justify-content  : center;
  background-color : rgba(210, 210, 210, 0.7);
  position         : fixed;
  top              : 0;
  left             : 0;
  z-index          : 1201;

  .modal-window {
    background-color : var(--color-white);
    border-radius    : var(--border-radius);
    border           : 1px solid lightgrey;
    box-shadow       : rgb(11 43 158 / 15%);
    padding          : 1rem 2rem 2rem;

    .close-icon-block {
      text-align      : right;
      font-size       : 3rem;
      font-weight     : 400;
      color           : gray;
      line-height     : 2.5rem;
      display         : flex;
      justify-content : space-between;
      align-items     : flex-start;
      margin-bottom   : 8px;

      button {
        cursor     : pointer;
        border     : none;
        outline    : none;
        background : transparent;
        font-size  : 2rem;
        color      : var(--color-gray);

        &:hover{
          opacity: .8;
        }
      }
    }

    .tag {
      margin-left      : 10px;
      margin-right     : 2px;
      background-color : rgba(210, 210, 210, 0.7);
      padding          : 0 5px;
      border-radius    : var(--border-radius);
      border           : 1px solid rgba(210, 210, 210, 0.9);
    }

    .info {
      display: flex;

      &__icon {
        margin-right: 10px;
        svg {
          & > g > path:first-child {
            fill: var(--color-primary)
          }
        }
      }
    }

    ul {
      padding: 0    0 0 20px;
      margin:  12px 0;
    }

    li {
      list-style-type : circle;
      color           : gray;
      font-size       : 1rem;
    }

    h2 {
      color         : var(--color-gray-dark-2);
      margin-bottom : 0.5rem;
    }

    p {
      margin-top    : 0;
      margin-bottom : 0.75rem;
      color         : gray;
      font-size     : 1rem;
    }
  }
}

.modal-window-base {
  &:focus-visible {
    outline: none;
  }
}
