.bold {
  font-weight: 600;
}


.decoration-none {
  text-decoration: none;
}

.md {
  font-size: 1rem;
}
.sm {
  font-size: 0.875rem;
}
.xs {
  font-size: 0.75rem;
}
