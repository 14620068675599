html, body, #root {
  height: 100%;
}

input[type="password"][value]::placeholder {
  text-shadow:var(--color-white);
}
input::-webkit-calendar-picker-indicator {
  display: none !important;
}
align[center] {
  text-align: center;
}
align[right] {
  text-align: right;
}
align[left] {
  text-align: left;
}

::-webkit-scrollbar {
  width: .25em;
}
::-webkit-scrollbar-track-piece {
  background: var(--color-gray-light);
}
::-webkit-scrollbar-thumb {
  background: var(--color-secondary-dark-4);
}
