.select {
  width: 100%;
  max-height: 48px;
  & .Mui-disabled {
    color: #000;
    background-color:var(--color-white);
    cursor: not-allowed;
    opacity: 1!important;
  }
  &.MuiInputBase-root {
    width      : 100%!important;
    max-height : 48px!important;

    & fieldset {
      border-color: var(--color-gray-light-4)
    }

    &.Mui-focused {
      & fieldset {
        border-width: 1px;
        border-color: var(--color-primary);
      }
    }

    &:hover {
      & fieldset {
        border-color: var(--color-primary);
      }
    }

    & > .MuiSelect-select {
      line-height: 1.75;
      display: flex;
      align-items: center;
      color: var(--color-secondary-dark-4);
      font-size: 1rem;
      font-weight: 400;
      text-shadow: 0 0 10px #fff;

      & * {
        color: inherit;
        background-color: inherit;
        font-size: inherit;
        text-shadow: 0 0 10px #fff;
      }

      & > .droplets-details__icon {
        margin-right: 10px;
        margin-top: 5px;
        & > svg {
          width: 24px;
          height: 24px;

          & > path {
            fill: var(--color-primary)
          }
        }
      }
    }
  }
}
