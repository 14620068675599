.jc-sb {
  justify-content: space-between;
}
.justify-between {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.ai-c {
  align-items: center;
}
.align-center {
  align-items: center;
}
.pc-c {
  align-items     : center;
  justify-content : center;
  place-content   : center;
}
