.invoice-failed-modal {
  width: 500px;

  &__subtitle {
    &.MuiTypography-root {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }

  &__description {
    &.MuiTypography-root {
      font-size: 16px;
      color: var(--color-gray);
    }
  }

  &__btn {
    &.MuiButtonBase-root {
      margin-top: 20px;
    }
    width: 100%;
  }
}
