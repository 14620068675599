:where(:root) {
  --light            : 10.875%;

  --hue              : 0;
  --saturation       : 90%;
  --lightness        : 50%;

  --light-1 : var(--light);
  --light-2 : calc(var(--light) * 2);
  --light-3 : calc(var(--light) * 3);
  --light-4 : calc(var(--light) * 4);

  --bright-1 : calc(var(--lightness) + (var(--light)));
  --bright-2 : calc(var(--lightness) + (var(--light-2)));
  --bright-3 : calc(var(--lightness) + (var(--light-3)));
  --bright-4 : calc(var(--lightness) + (var(--light-4)));

  --dark-1 : calc(var(--lightness) - (var(--light)));
  --dark-2 : calc(var(--lightness) - (var(--light-2)));
  --dark-3 : calc(var(--lightness) - (var(--light-3)));
  --dark-4 : calc(var(--lightness) - (var(--light-4)));

  --color-white : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness) + 50%));
  --color-black : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness) - 50%));

  /* primary */
  --hue-primary        : 30;
  --saturation-primary : var(--saturation);
  --lightness-primary  : var(--lightness);

  --color-primary-light-4 : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) + var(--light-4)));
  --color-primary-light-3 : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) + var(--light-3)));
  --color-primary-light-2 : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) + var(--light-2)));
  --color-primary-light   : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) + var(--light-1)));
  --color-primary         : hsl(var(--hue-primary), var(--saturation-primary), var(--lightness-primary));
  --color-primary-dark    : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) - var(--light-1)));
  --color-primary-dark-2  : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) - var(--light-2)));
  --color-primary-dark-3  : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) - var(--light-3)));
  --color-primary-dark-4  : hsl(var(--hue-primary), var(--saturation-primary), calc(var(--lightness-primary) - var(--light-4)));

  /* secondary */
  --hue-secondary        : 210;
  --saturation-secondary : var(--saturation);
  --lightness-secondary  : var(--lightness);

  --color-secondary-light-4 : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) + var(--light-4)));
  --color-secondary-light-3 : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) + var(--light-3)));
  --color-secondary-light-2 : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) + var(--light-2)));
  --color-secondary-light   : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) + var(--light-1)));
  --color-secondary         : hsl(var(--hue-secondary), var(--saturation-secondary), var(--lightness-secondary));
  --color-secondary-dark    : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) - var(--light-1)));
  --color-secondary-dark-2  : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) - var(--light-2)));
  --color-secondary-dark-3  : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) - var(--light-3)));
  --color-secondary-dark-4  : hsl(var(--hue-secondary), var(--saturation-secondary), calc(var(--lightness-secondary) - var(--light-4)));

  /* success */
  --hue-success        : 120;
  --saturation-success : var(--saturation);
  --lightness-success  : var(--lightness);

  --color-success-light-4 : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) + var(--light-4)));
  --color-success-light-3 : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) + var(--light-3)));
  --color-success-light-2 : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) + var(--light-2)));
  --color-success-light   : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) + var(--light-1)));
  --color-success         : hsl(var(--hue-success), var(--saturation-success), var(--lightness-success));
  --color-success-dark    : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) - var(--light-1)));
  --color-success-dark-2  : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) - var(--light-2)));
  --color-success-dark-3  : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) - var(--light-3)));
  --color-success-dark-4  : hsl(var(--hue-success), var(--saturation-success), calc(var(--lightness-success) - var(--light-4)));

  /* dangery */
  --hue-dangery        : 0;
  --saturation-dangery : var(--saturation);
  --lightness-dangery  : var(--lightness);

  --color-dangery-light-4 : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) + var(--light-4)));
  --color-dangery-light-3 : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) + var(--light-3)));
  --color-dangery-light-2 : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) + var(--light-2)));
  --color-dangery-light   : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) + var(--light-1)));
  --color-dangery         : hsl(var(--hue-dangery), var(--saturation-dangery), var(--lightness-dangery));
  --color-dangery-dark    : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) - var(--light-1)));
  --color-dangery-dark-2  : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) - var(--light-2)));
  --color-dangery-dark-3  : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) - var(--light-3)));
  --color-dangery-dark-4  : hsl(var(--hue-dangery), var(--saturation-dangery), calc(var(--lightness-dangery) - var(--light-4)));

  /* gray */
  --hue-gray             : var(--hue-primary);
  --saturation-gray      : 0%;
  --lightness-gray       : var(--lightness);

  --color-gray-light-4   : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) + var(--light-4)));
  --color-gray-light-3   : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) + var(--light-3)));
  --color-gray-light-2   : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) + var(--light-2)));
  --color-gray-light     : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) + var(--light-1)));
  --color-gray           : hsl(var(--hue-gray), var(--saturation-gray), var(--lightness-gray));
  --color-gray-dark      : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) - var(--light-1)));
  --color-gray-dark-2    : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) - var(--light-2)));
  --color-gray-dark-3    : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) - var(--light-3)));
  --color-gray-dark-4    : hsl(var(--hue-gray), var(--saturation-gray), calc(var(--lightness-gray) - var(--light-4)));

  --border-width     : 0.0625em;
  --border-width-2   : calc(var(--border-width) * 2);
  --border-style     : solid;
  --border-color     : var(--color-gray-light-3);
  --border-radius    : 0;
  --border-radius-2  : calc(var(--border-radius) * 2);
  --border-radius-5  : calc(var(--border-radius) * 5);
  --border-radius-6  : calc(var(--border-radius) * 6);
  --border           : var(--border-width) var(--border-style) var(--border-color);

  --box-shadow-color : var(--color-gray-light-3);
  --box-shadow-inset : 0.125em 0.125em 0.5em;
  --box-shadow       : var(--box-shadow-inset) var(--masked-background);

  --offset    : 1rem;
  --offset-lg : calc(var(--offset) * 1.5);
  --offset-2  : calc(var(--offset) * 2);
  --offset-3  : calc(var(--offset) * 3);
  --offset-4  : calc(var(--offset) * 4);

  --container-width-lg : 75rem;
  --container-width-md : 43.75rem;
  --container-width-sm : 30rem;
  --container-width-xs : 24rem;

  --masked-background : hsl(calc(var(--hue-secondary) + 20), calc(var(--saturation) - 40%), calc(var(--bright-4) + 2.5%));
  --box-shadow-paper  : rgb(11 43 158 / 15%) 0 6px 20px -6px;
}
