.button-menu {
  &.MuiButton-root {
    text-transform: capitalize;
    color: var(--color-white);
    &.up {
      & .MuiSvgIcon-root {
        transform: rotate(180deg);
      }
    }
  }
}

.button-popover {
  & .MuiPaper-root {
    margin-top: 15px;
    background-color: #FFFFFF;
    overflow: visible;
    border: var(--border);

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      right: calc(50% - 10px);
      display: block;
      background-color: #FFFFFF;
      border-top: 1px solid var(--color-gray-light-4);
      border-left: 1px solid var(--color-gray-light-4);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      z-index: 10;
    }
  }
}

.menu-products {
  &.MuiList-root {
    padding: 6px;

    & .MuiListItem-root.disabled {
      cursor: not-allowed;
      opacity: .6;
    }

    & .MuiListItem-root {
      padding: 6px 11px;
      cursor: pointer;

      &:hover{
        border-radius : var(--border-radius);
        background-color: var(--color-primary-light-4);
      }
    }
  }

  &__link {
    display: flex;
    text-decoration: none;
    color: var(--color-gray-light);
    align-items: center;
  }

  &__title {
    font-size: 1rem;
    color: var(--color-secondary-dark-4);
    line-height: 1.45rem;
  }

  &__description {
    font-size: .8125rem;
    line-height: 1rem;
  }
}

.icon-create {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  & > svg {
    width: 25px;
    height: 25px;
    &:not(.no-fill) {
      & > g > rect {
        fill: var(--color-primary);
      }
      & #Rectangle-path {
        fill: var(--color-primary);
      }

      & path {
        fill: var(--color-primary);
      }

      & > rect {
        fill: var(--color-primary);

        &:last-child {
          fill: transparent;
        }
      }
    }
  }
}
