.dashboard-header {
  &__account-wrapper{
    text-align      : right;
    display         : flex;
    justify-content : flex-end;
    margin-left     : var(--offset);
  }

  &__account-name {
    &.MuiTypography-root{
      font-size   : 1rem;
      line-height : 1.45rem;
      color       : var(--color-secondary-dark-4);
      font-weight : 600;
    }
  }

  &__balance {
    &.MuiTypography-root{
      font-size   : 14px;
      line-height : 1.45rem;
      color       : var(--color-gray-light)
    }
  }

  &__avatar-small {
    &.MuiAvatar-root {
      width  : 22px;
      height : 22px;
    }
  }

  &__avatar-wrap {
    cursor      : pointer;
    display     : flex;
    align-items : center;

    & .MuiSvgIcon-root{
      color: #8390af;

      &.up {
        transform: rotate(180deg);
      }
    }
  }
}

.menu-customer {
  a {
    text-decoration: none;
  }

  &__teams {
    &.MuiList-root{
      padding-bottom: 0;
    }
  }

  &__back-menu {
    &.MuiListItem-root {
      display     : flex;
      align-items : center;
      gap         : 3px;
    }

    & > div {
      display     : flex;
      align-items : center;

      & > svg {
        width  : 12px;
        height : 12px;
      }
    }
  }

  &__current-team{
    &.MuiListItem-root {
      display          : flex;
      align-items      : center;
      gap              : 15px;
      background-color : var(--color-primary);
      pointer-events   : none;

      & > div {
        color:var(--color-white);
      }
    }

    & > div {
      display     : flex;
      align-items : center;

      & > svg {
        width  : 20px;
        height : 20px;
      }
    }
  }

  &__switch-team {
    &.MuiListItem-root {
      display         : flex;
      align-items     : center;
      justify-content : space-between;
    }

    & > div {
      display     : flex;
      align-items : center;

      & > svg {
        width  : 12px;
        height : 12px;
      }
    }
  }

  &.MuiList-root {

    & .MuiListItem-root {
      padding     : 10px 16px;
      font-weight : 400;
      color       : var(--color-secondary-dark-4);
      font-size   : 1rem;
      cursor      : pointer;

      &:hover {
        background-color : #e5e8ed;
        font-weight      : 600;
      }
    }
  }
}

.profile {
  display     : flex;
  align-items : center;
  padding     : 0 16px;
  &__info-wrapper {
    margin-left : 9px;
    white-space : nowrap;
    overflow    : hidden;

    & .MuiTypography-root:nth-child(1) {
      font-size     : 1rem;
      color         : #031b4e;
      white-space   : nowrap;
      overflow      : hidden;
      text-overflow : ellipsis;
      font-weight   : 600;
      text-align    : left;
    }

    & .MuiTypography-root:nth-child(2) {
      font-size     : 14px;
      color         : var(--color-secondary-dark-4);
      white-space   : nowrap;
      overflow      : hidden;
      text-overflow : ellipsis;
      text-align    : left;
    }
  }
}

.profile-container {
  display          : flex;
  flex-direction   : column;
  padding          : 16px 0;
  width            : 270px;
  overflow         : hidden;
  background-color:var(--color-white);

  &__teams {
    padding: 16px 0 0 0;
  }
}

.profile-popover {
  & .MuiPaper-root {
    margin-top: 10px;
  }
}

.referral {
  display       : flex;
  padding       : 10px;
  margin        : 0 16px;
  max-width     : 228px;
  border        : 2px solid #fa8a4c;
  background    : #ffe1a5;
  border-radius : var(--border-radius);
  color         : #000;

  &__info-wrapper {
    margin-left: 10px;

    & a {
      color       : var(--color-primary);
      font-weight : 600;
      font-size   : 1rem;
      font-family : 'Proxima Nova', sans-serif;
    }

    & .MuiTypography-root {
      font-size   : 1rem;
      line-height : 1.5;
    }
  }
}

.create-team-link {
  color           : var(--color-primary);
  text-decoration : none;
  &[aria-disabled="true"] {
    cursor         : not-allowed!important;
    pointer-events : none;
    color          : var(--color-gray-light);
    opacity        : 0.5;
  }
}

