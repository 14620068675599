.button-component.MuiButtonBase-root {
  color:var(--color-white);
  border-width     : 0.0625em;
  border-style     : solid;
  border-color     : var(--main-color, var(--color-primary));
  padding          : 0 1.6rem;
  font-size        : .9rem;
  height           : 3rem;
  line-height      : 1.2rem;
  background-color : var(--color-primary);
  text-transform   : none;
  font-weight      : 600;
  min-width        : 140px;

  &:hover {
    background-color: var(--color-primary-dark);
  }

  &:disabled,
  &.disabled {
    border-color     : var(--color-dangery-light-4);
    cursor           : not-allowed;
    pointer-events   : none;
    user-select      : none;
    color            : var(--color-gray-light-3);
    background-color : var(--color-dangery-light-4);
  }

  // TRANSPARENT STYLE
  &.transparent-button {
    background-color:var(--color-white);
    color            : var(--main-color, var(--color-primary));
    border-width     : 2px;
    font-weight      : 600;

    &:hover {
      color:var(--color-white);
      background-color : var(--main-color, var(--color-primary));
    }
    &.disabled,
    &:disabled {
      color : var(--color-dangery-light-4);
    }
  }

  // GRAY STYLE
  &.gray-button {
    background-color : lightgrey;
    color            : var(--color-gray-dark-2);
    border-width     : 2px;
    border-color     : lightgrey;
    font-weight      : 600;
    &:hover {
      background-color : darkgray;
      border-color     : darkgray;
    }
    &:disabled {
      opacity : .5;
      cursor  : not-allowed;
    }
  }
}
