.search-resource {
  &.MuiAutocomplete-root {
    max-width: 500px;
  }
  & fieldset {
    border:none
  }
  & input {
    color:var(--color-gray-light);
    font-size: 15px;
    &::placeholder{
      color:var(--color-gray-light);
      font-size: 15px;
      opacity: .8;
    }
  }
}

.zoom-icon {
  &.MuiSvgIcon-root{
    margin-top: 1px;
    width: 18px;
    color:var(--color-gray-light);
  }
}

.MuiAutocomplete-popper {
  &>.MuiPaper-root{
    background-color: #FFFFFF;
  }
  & .MuiAutocomplete-noOptions{
    font-size: 1rem;
  }
  & .MuiAutocomplete-loading{
    font-size: 1rem;
  }
}
