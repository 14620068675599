.maintenance-page {
  padding          : 69px 60px 0;
  display          : flex;
  flex-direction   : column;
  align-items      : center;
  justify-content  : center;
  height           : 100vh;
  text-align       : center;
  background-color : var(--color-secondary-dark-4);

  &__title {
    font-size     : clamp(2rem, 1.6rem + 0.8vw, 3rem);
    margin-bottom : 0.3rem;
    color:var(--color-white);
  }

  &__subtitle {
    font-size  : clamp(1.5rem, 1.1rem + 0.8vw, 2.5rem);
    margin-top : 0;
    color:var(--color-white);
  }

  &__img {
    max-height : 50vh;
    height     : auto;
  }
}
