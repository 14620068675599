.border {
  border: var(--border);
}

.rounded {
  border-radius: var(--border-radius);
}

:is(.bordering) {
  border        : var(--border);
  padding       : var(--offset-2);
  border-radius : var(--border-radius);
}
