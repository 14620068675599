.page-loader-component {
  width    : 100%;
  height   : 100%;
  display  : block;
  position : relative;
  cursor   : wait;
  .loader-circle {
    display  : inline-block;
    position : absolute;
    width    : 80px;
    height   : 80px;
    inset    : 0;
    margin   : auto;
    &::before,
    &::after {
      content       : "";
      display       : block;
      position      : absolute;
      border        : 7px solid var(--color-primary);
      opacity       : 1;
      border-radius : 50%;
      width         : 72px;
      height        : 72px;
      transform     : scale(1);
      animation     : loader-circle 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    &::after {
      animation-delay: -0.5s;
    }
  }

  @keyframes loader-circle {
    0% {
      transform : scale(0);
      opacity   : 0;
    }
    5% {
      transform : scale(0);
      opacity   : 1;
    }
    100% {
      transform : scale(1);
      opacity   : 0;
    }
  }
}
