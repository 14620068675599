.side-bar {
  width       : var(--sidebar-width);
  flex-shrink : 0;

  &__project-wrapper {
    & .MuiListItemIcon-root {
      margin-right: 10px;

      & > svg {
        width  : 24px;
        height : 24px;
      }
    }
  }

  &__project-item {
    backdrop-filter: invert(.1);
  }

  &__drawer {
    & > .MuiPaper-root {
      box-sizing: border-box;
      width: var(--sidebar-width);
      border: none;
    }
  }


  &__accordion {
    &.MuiAccordion-root {
      box-shadow: none;
      border-bottom: var(--border);

      &.last-group {
        border-bottom: none;
      }

      &.Mui-expanded {
        margin: 0;
      }

      &.MuiPaper-root {
        border-radius: 0;
      }
    }

    & .MuiAccordionSummary-root {
      &.sideBar__summary {
        &.Mui-expanded {
          min-height: 64px;
        }
      }

      &.Mui-expanded {
        min-height: 64px;
      }

      & svg {
        width: 20px;
        height: 20px;
      }

      & svg > path {
        fill: var(--color-white, #FFFFFF);
      }
    }

    & .MuiAccordionSummary-content {
      &.Mui-expanded {
        margin: 12px 0;
      }

      & > .MuiTypography-root {
        text-transform : uppercase;
      }
    }

    & .MuiAccordionDetails-root {
      padding: 0 0 20px 0;

      &.side-bar__details_account {
        padding: 20px 0;
      }

      &.side-bar__details_footer {
        padding: 12px 0;
      }

      & .MuiList-root {
        padding: 0;
      }

      & .MuiList-root {
        overflow      : hidden;
        text-overflow : ellipsis;
        width         : 100%;
        white-space   : nowrap;

        & .MuiListItemIcon-root {
          min-width: auto;
        }

        & .MuiListItem-root {

          &.disabled {
            opacity: .6;

            & > a {
              cursor: not-allowed;

              & > .MuiButtonBase-root {
                cursor: not-allowed;
              }
            }
          }

          & > a {
            padding         : 10px 20px;
            text-decoration : none;
            color           : inherit;
            display         : block;
            width           : 100%;
          }
        }

        & .MuiListItemButton-root {
          padding    : 0;
          max-height : 20px;

          &:hover {
            pointer-events   : none;
            background-color : transparent;
          }

          & .MuiListItemText-root {
            flex-grow: 0;
          }

          & .MuiTypography-root {
            font-weight : 400;
            font-size   : 1rem;
          }
        }
      }

      & .MuiListItem-root {
        max-height: 40px;

        &:hover {
          background-color: var(--color-primary);

          & > a > div > .MuiListItemIcon-root {
            & > svg {
              &  path {
                fill: var(--color-white, #FFFFFF);
              }
            }
          }
        }

        & .MuiTypography-root {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.active {
          background-color: rgba(235, 240, 249, .2);

          &:hover {
            background-color: rgba(235, 240, 249, .2);

            & > a > div > .MuiListItemIcon-root {
              & > svg {
                & path {
                  fill: var(--color-primary);
                }
              }
            }
          }

          & .MuiTypography-root {
            font-weight : 700;
            font-size   : 1rem;
          }
        }

        &.addGroup {
          color          : var(--color-primary);
          cursor         : pointer;
          transition     : all .2s ease-in-out;
          backdrop-filter: invert(.4);


          &.disabled {
            & > a {
              cursor: not-allowed;

              & > .MuiBox-root {
                & > .MuiListItemIcon-root {
                  cursor: not-allowed;
                }

                & > .MuiButtonBase-root {
                  cursor: not-allowed;
                }
              }
            }
          }

          &:hover {
            background-color: inherit;
          }

          & .MuiTypography-root {
            font-weight: 400;
            line-height: 1rem;
          }

          & .MuiSvgIcon-root {
            transition    : all .3s ease-in-out;
            border-radius : var(--border-radius);
          }

          &.disabled:hover {
            & .MuiSvgIcon-root > path {
              fill: var(--color-primary);
            }

            & .MuiSvgIcon-root {
              background-color: inherit;
            }
          }

          & .MuiSvgIcon-root > path {
            fill: var(--color-primary);
          }

          &:hover {
            & .MuiSvgIcon-root > path {
              fill: var(--color-white, #FFFFFF);
            }

            & .MuiSvgIcon-root {
              background-color: var(--color-primary);
            }
          }
        }
      }
    }
  }
}

.project-icon {
  display             : inline-block;
  margin-right        : 10px;
  flex-shrink         : 0;
  background-position : -21.5px -1103.5px;
  width               : 20px;
  height              : 20px;
  background-size     : 1147px 1124px;
  border-radius       : calc(var(--border-radius) / 2);
  background-clip     : padding-box;
}
