.shared-destroy-modal {
  width          : 550px;
  display        : flex;
  flex-direction : column;
  gap            : 12px;

  p {
    font-size : 16px;
    color     : var(--color-gray);
    margin    : 0;
  }

  .highlighted {
    margin-top       : 12px;
    border           : 1px solid #dfdfdf;
    background-color : #F6F6F6;
    padding          : 13px 17px;
    border-radius    : var(--border-radius);
    font-size        : 0.875rem;
    font-weight      : 600;
    color            : var(--color-primary);

    &.mt-0 {
      margin-top: 0;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    font-size: 1rem;

    .MuiFormLabel-root {
      font-size: 1rem;
      top: -4px;

      &.Mui-focused {
        top: 0;
      }

      &.MuiFormLabel-filled{
        top: 0;
      }
    }

    .MuiInputBase-root {
      height: 45px;

      input {
        font-size: 1rem;
      }
    }
  }

  .MuiButtonBase-root {
    width: 49%;

    &.red-button {
      background-color : var(--color-dangery);
      color:var(--color-white);
      border-color     : var(--color-dangery);

      &:hover{
        background-color : rgb(237, 79, 50, 0.9);
        color:var(--color-white);
        border-color     : rgb(237, 79, 50, 0.9);
      }

      &.disabled {
        background-color: var(--color-dangery-light-4);
        color: #c7c7c7;
        pointer-events: none;
        border-color: var(--color-dangery-light-4);
      }
    }

    &.yellow-button {
      background-color : var(--color-primary);
      color:var(--color-white);
      border-color     : var(--color-primary);

      &.disabled {
        background-color : var(--color-dangery-light-4);
        color            : #c7c7c7;
        pointer-events   : none;
        border-color     : var(--color-dangery-light-4);
      }
    }
  }
}
