.error-page {
  display         : flex;
  height          : 100vh;
  width           : 100vw;
  align-items     : center;
  flex-direction  : column;
  justify-content : center;

  &__title {
    &.MuiTypography-root {
      font-size : clamp(1.4rem, 2.5vw, 2rem);
      color     : var(--color-primary);
    }
  }

  &__icon {
    width:           100%;
    align-items:     center;
    display:         flex;
    height:          auto;
    justify-content: center;
    svg {
      width      : 80%;
      height     : 80%;
      max-width  : 1025px;
      max-height : 785px;
    }
  }
}
