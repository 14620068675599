.invoice-paid-modal {
  width: 500px;

  &__description {
    &.MuiTypography-root {
      font-size : 16px;
      color     : var(--color-gray);
    }
  }

  &__subtitle {
    &.MuiTypography-root {
      font-size     : 18px;
      font-weight   : 600;
      margin-bottom : 6px;
    }
  }

  &__description {
    &.MuiTypography-root {
      font-size : 16px;
      color     : var(--color-gray);
    }
  }

  &__btn {
    &.MuiButtonBase-root {
      margin-top: 12px;
    }
    width: 100%;
  }
}
