.switch-window {
  background-color : #FFFFFF;
  width            : 100vw;
  height           : 100vh;
  position         : fixed;
  z-index          : 10000;
  top              : 0;
  left             : 0;
  display          : flex;
  align-content    : flex-start;
  justify-content  : center;

  &__content {
    display         : flex;
    align-content   : center;
    justify-content : center;
    flex-direction  : column;
  }

  &__title {
    margin     : 0;
    font-size  : 24px;
    color      : var(--color-gray-dark-2);
    text-align : center;
  }

  &__teams{
    display         : flex;
    align-items     : center;
    width           : 300px;
    justify-content : space-between;
    margin-top      : 24px;

    & > span {
      margin: 0;
    }
  }
}
