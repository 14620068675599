.visual-hidden {
  position         : absolute;
  font-size        : 1px;
  margin           : -1px;
  padding          : 0;
  border           : none;
  background-color : inherit;
  background       : transparent;
  clip             : rect(0 0 0 0);
  clip-path        : inset(50%);
  overflow         : hidden;
  min-height       : none;
  max-height       : none;
  width            : 1px;
  height           : 1px;
  max-width        : 1px;
  max-height       : 1px;
  cursor           : inherit;
  outline          : none;
  box-shadow       : none;
  z-index          : -1;
  display          : inline;
  line-height      : 1px;
}
.visual-hidden:after,
.visual-hidden:before{
  content    : none;
  display    : none;
  width      : 0;
  height     : 0;
  padding    : 0;
  border     : none;
  visibility : hidden;
}

.flow.flow > * + * {
  margin-top: var(--offset);
}

.inherit {
  color           : inherit;
  text-decoration : inherit;
  font            : inherit;
}

.w-100 {
  width: 100%;
}

.mt {
  margin-top: var(--offset);
}

.mt-2 {
  margin-top: var(--offset-2);
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.list-style-none {
  list-style: none;
}
:is(.list-style-none) {
  margin  : 0;
  padding : 0;
}

.disabled {
  opacity: .5;
  cursor : not-allowed;
}

.pair {
  display   : flex;
  flex-wrap : wrap;
  gap       : var(--offset);
  & > :where(*) {
    flex-grow  : 1;
    flex-basis : calc(50% - var(--offset));
  }
}
