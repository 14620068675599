// .mui-text-field {
  //   border-radius: var(--border-radius);
  //   &.textarea {
    //     textarea {
      //       font-size   : 12px;
      //       line-height : 14px;
      //       &::-webkit-scrollbar {
        //         display: none;
      //       }
    //     }
  //   }

  //   .Mui-focused.MuiFormLabel-root {
    //     color: var(--color-primary);
    //     &.Mui-error {
      //       color: #d32f2f;
    //     }
  //   }

  //   p.MuiFormHelperText-root.Mui-error {
    //     margin: 10px 0 0 0;
    //     font-size: 14px;
  //   }

  //   .MuiInputBase-root{
    //     .Mui-disabled{
      //       cursor: not-allowed;
    //     }
  //   }

  //   .MuiInputBase-root:not(.Mui-disabled) {
    //     &:hover {
      //       fieldset {
        //         border-color: var(--color-primary);
      //       }
    //     }

    //     &.Mui-focused {
      //       fieldset {
        //         border-color: var(--color-primary);
      //       }

      //       .MuiFormLabel-root {
        //         color: var(--color-primary);
      //       }
    //     }

    //     &.Mui-error {
      //       &:hover {
        //         fieldset {
          //           border-color: #d32f2f;
        //         }
      //       }

      //       &.Mui-focused {
        //         fieldset {
          //           border-color: #d32f2f;
        //         }
      //       }
    //     }
  //   }

  //   &.MuiFormControl-root {
    //     width         : 100%;
    //     margin-bottom : 1rem;
    //     font-size     : 16px;

    //     &.m0{
      //       margin-bottom: 0;
    //     }

    //     .MuiFormHelperText-root {
      //       font-size     : 12px;
      //       color         : var(--color-dangery);
      //       margin-bottom : 0;
      //       line-height   : 12px;
      //       margin-top    : 2px;
    //     }
  //   }
// }

.mui-select-field {
  margin-top: 6px;
  .MuiList-root {
    max-height: 400px;

    li {
      color     : black;
      font-size : 16px;

      &:hover {
        background-color: var(--color-primary-light-4);
      }
    }
  }

  .MuiPaper-root {
    background-color:var(--color-white);
  }
}

.MuiTelInput-Menu {
  & > .MuiPaper-root {
    background-color : #FFFFFF;
    color            : rgba(0, 0, 0, 0.87);
    font-size        : 14px;
    max-height       : 400px;
    max-width        : 550px;
    width            : 100%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
