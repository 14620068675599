.grecaptcha-badge {
  transform  : translate(58px);
  transition : transform 0.3s ease-in-out;
  &:hover {
    transform: translate(0);
  }
}

.dashboard-wrapper {
  width: 100%;
  overflow-x: auto;
}

.dashboard {
  &-layout {
    --sidebar-width : 12.5rem;
    display         : flex;
    height          : 100%;
  }
  &-header {
    position  : fixed!important;
    top       : 0;
    right     : 0;
    left      : var(--sidebar-width);
    max-width : calc(100% - var(--sidebar-width));
  }
  &-wrapper {
    margin-top: 70px;
    min-height: calc(100% - 70px)!important;
    height    : auto!important;
  }
  @media (max-width: 768px) {
    &-header {
      left      : 0;
      max-width : 100%;
      z-index   : 9999;
    }
    &-side-bar {
      top      : 70px;
      position : relative;
      & > div > .MuiPaper-root {
        top            : 70px;
        padding-bottom : 70px;
        font-size      : .75rem;
      }
    }
  }
}

.hide-wrapper {
  transition       : .5s;
  background-color : rgba(51, 51, 51, .2);
}

.account-tip,
.deactivate-tip {
  font-size        : 1rem;
  color            : var(--color-primary);
  padding          : 5px 15px;
  display          : flex;
  align-items      : center;
  & > svg {
    margin-right: 5px;
    circle {
      fill : var(--color-danger);
    }
  }
}

.account-tip {
  background-color : var(--color-primary-light-4);
  color            : var(--color-primary);
}
.deactivate-tip {
  background-color : var(--color-dangery-light-4);
  color            : var(--color-dangery);
}
