.loader {
  --size              : 24px;
  width               : var(--size);
  height              : var(--size);
  min-width           : var(--size);
  min-height          : var(--size);
  aspect-ratio        : 1;
  border-width        : var(--border-width-2);
  border-color        : var(--color-primary);
  border-style        : solid;
  border-bottom-color : transparent;
  border-radius       : 50%;
  display             : inline-block;
  box-sizing          : border-box;
  animation           : rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
