.logo-wrapper {
  max-width: min(168px, 100%);
  max-height: min(80px, 100%);
  height: 100%;
}

.logo-wrapper svg {
  display: block;
  width: 100%;
  height: auto;
  max-height: 100%;
}
