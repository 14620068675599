.link {
  color           : var(--color-primary);
  text-decoration : none;
  cursor          : pointer;
}
.link[aria-disabled="true"] {
  opacity : .5;
  cursor  : not-allowed;
}
.link:hover {
  opacity: .8;
}

.button,
.button-outline {
  --color         : var(--color-primary);
  text-decoration : none;
  cursor          : pointer;
  border-color    : var(--color);
  border-width    : var(--border-width);
  border-style    : solid;
  border-radius   : var(--border-radius);
  padding         : .5em 1em;
  line-height     : 1.5em;
  display         : inline-block;
  text-align      : center;
  outline         : none;
}

:is(:hover, :focus):where(
  .button,
  .button-outline
) {
  opacity            : .8;
  --color            : var(--color-primary);
  box-shadow         : var(--box-shadow);
}
:disabled:where(
  .button,
  .button-outline
) {
  opacity : .5;
  cursor  : not-allowed;
  filter  : grayscale(80%);
}

.button {
  background-color: var(--color);
  color           : var(--color-white);
}
.button-outline {
  background-color: transparent;
  color           : var(--color);
}
.button-outline[aria-disabled="true"] {
  opacity: .5;
  cursor : not-allowed;
}
