.content {
  max-width      : 1200px;
  width          : 100%;
  margin-inline  : auto;
  padding        : var(--offset) var(--offset-2);
}

.small-gray-text {
  margin      : 0;
  line-height : 1.45rem;
  font-size   : 1rem;
  color       : var(--color-gray-light);
}


.small-code {
  font-family      : monospace,serif;
  font-size        : .85rem;
  background-color : #f1f1f1;
  display          : inline-block;
  padding-inline   : .4em;
}

.min-h-1 {
  margin-top  : .2em;
  min-height  : 1.2em;
  line-height : 1.2em;
}

.action {
  position: relative;
  --action: -1em;

  &-sm {
    --action: -0.5em;
  }

  &-lg {
    --action: -1.5em;
  }

  &-xl {
    --action: -2em;
  }

  &-x::after {
    left  : var(--action);
    right : var(--action);
  }

  &-y::after {
    top    : var(--action);
    bottom : var(--action);
  }

  &-left::after {
    left: var(--action);
  }

  &-right::after {
    right: var(--action);
  }

  &-top::after {
    top: var(--action);
  }

  &-bottom::after {
    bottom: var(--action);
  }
}
