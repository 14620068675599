:is(.container, .container-md) {
  --container-width  : var(--container-width-lg);
  --container-offset : var(--offset-lg);
  max-width          : var(--container-width);
  padding-inline     : var(--container-offset);
  width              : 100%;
  margin-left        : auto;
  margin-right       : auto;
  margin-inline      : auto;
}

:is(.container-md) {
  --container-width  : var(--container-width-md);
  --container-offset : var(--offset);
}
